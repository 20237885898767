.input {
    width: 100%;
    height: 30px;
    border: solid 1px var(--black-150);
    background-color: transparent;
    outline: none;
    padding: 8px 14px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
    border-radius: 3px;
}

.input:disabled {
    background-color: var(--grey-100);
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.input::placeholder {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-200);
}
