.root {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 32px;
    min-width: 76px;
    background-color: var(--tertiary-500);
    color: var(--whitebase);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
}

.root:active {
    background-color: var(--tertiary-600);
}

.root:disabled {
    background-color: var(--black-100);
    cursor: auto;
}

.root:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}
