.root {
    font-family: "NeuzeitGroT", Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    margin: 0;
    font: inherit;
    background-color: var(--whitebase);
    background-image: none;
    border: 1.2px solid var(--klassapp-300);
    width: 100%;
    align-items: center;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 5px 10px;
    display: inline-block;
}

.root:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.root:disabled {
    cursor: not-allowed;
    background-color: #eee;
    border: 1px solid #bebebe;
}

.rootError {
    border-color: #df1642;
}

.rootError:focus {
    border-color: #df1642;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(223, 22, 66, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(223, 22, 66, 0.6);
}

.error {
    margin-top: 3px;
    color: #df1642;
    margin-bottom: 5px;
}

.captchaContainer {
    display: inline-flex;
    width: 100%;
}

.textImg svg {
    width: 150px;
    height: 50px;
}

.textImg,
.iconRefresh {
    display: inline-block;
}

.textImg {
    width: 80%;
    text-align: center;
}

.iconRefresh {
    width: 20%;
    cursor: pointer;
}

.iconRefresh table {
    width: 100%;
    text-align: center;
}

.iconRefresh:hover {
    background-color: var(--klassapp-1);
}

.title {
    text-align: left;
    color: var(--klassapp-300);
    margin-bottom: 2px;
    font-weight: 800;
}

.subtitle {
    text-align: left;
    font-size: 10px;
    font-style: italic;
    color: var(--klassapp-250);
}

.labelCheck {
    margin: 0 0 2px 0;
    color: var(--klassapp-300);
    font-size: 12px;
    text-align: left;
}

.text {
    font-size: 12px;
    font-style: italic;
}

.checkBoxWrapper {
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkBoxWrapper div div {
    display: flex;
    place-content: center;
}
