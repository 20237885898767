.iframe {
    position: absolute;
    height: calc(100% - 20px);
    width: 100%;
    border: 0;
}

.panel {
    width: 100%;
    height: 20px;
    background-color: #e3e3e3;
}
